(function($) {

  // basic setup for d3
  /**
   * Updated July 2019 to support jquery 3:
   * - event shorthands (like .click()) are deprecated: https://github.com/jquery/jquery-migrate/blob/master/warnings.md#jqmigrate-jqueryfnclick-event-shorthand-is-deprecated
   */
  $.Control.registerMixin('d3_base', {
    /*
    the world ain't ready for this. the default method is more brute force than d3 needs.
    but the events are too crazy to detangle and unbind.
    so, use safe strats for now.
    ready: function()
    {
        var vizObj = this;

        var wereEventsBound = vizObj._boundViewEvents;
        vizObj._super();

        // this will definitely be called before _super, so just rely on that to
        // mark this flag appropriately
        if (!wereEventsBound)
        {
            var handleQueryChange = function()
            {
                debugger;
                vizObj.getColumns();
                vizObj.getDataForAllViews();
            };

            _.each(vizObj._dataViews, function(view)
            {
                view.unbind('query_change')
                view.bind('query_change', handleQueryChange, vizObj);
            });
        }
    },*/

    requiresSeriesGrouping: window.Model.pureVirtual,

    // Sets a DOM element to overlay the chart.
    // Takes a single argument, the dom node.
    _setChartOverlay: window.Model.pureVirtual,

    // Hides or shows the chart render area.
    // Takes a boolean.
    _setChartVisible: window.Model.pureVirtual,

    getRequiredJavascripts: function() {
      // get d3 stuffs
      return blist.util.assetLoading.getJavascriptSources([{ assets: 'd3' }]);
    },

    _animationLengthMillisec: 600,

    // Handle rendering values for different column types here
    _renderCellText: function(row, col) {
      var renderer = row.invalid[col.lookup] ? blist.datatypes.invalid.renderer :
        col.renderType.renderer;
      return renderer(row.data[col.lookup], col, true, false, {}, true);
    },

    _d3_text: function(transform) {
      var hasTransform = _.isFunction(transform);
      return function(d) {
        this.textContent = (hasTransform ? transform(d) : d);
      };
    },

    _getDisplayFormatColors: function() {
      var colors = _.filter(this._displayFormat.colors, _.isString);
      return (_.isEmpty(colors) && $.isPresent(this._displayFormat.color)) ? [this._displayFormat.color] : colors;
    },

    _getFallbackColors: function() {
      return ['#042656', '#19538b', '#6a9feb', '#bed6f7', '#495969', '#bbc3c9'];
    },

    _d3_getColor: function(colDef, d) {
      var vizObj = this;

      var color = d ? d.color : undefined;

      if (!color) {
        var index = -1;
        var found = _.find(vizObj.getValueColumns(), function(value, i) {
          if (value.column.id == colDef.column.id) {
            index = i;
            return true;
          }
          return false;
        });


        var explicitColors = vizObj._getDisplayFormatColors();

        // Color priority is:
        // 1) Explicitly provided colors array.
        // 2) colDef.color.
        // 3) default colors.

        if (found && !_.isUndefined(explicitColors) && explicitColors.length > index) {
          color = explicitColors[index];
        } else if (colDef.color) {
          color = colDef.color;

          // Some legacy case where colDef.color is '#hex,#hex,#hex,#hex,#hex,#hex'.
          if (color.indexOf(',') > -1) {
            color = color.split(',')[0];
          }
        } else {
          // Fallback to default colors.
          var fallbackColors = vizObj._getFallbackColors();
          // Even if we didn't find the column, return something.
          index = found ? index : 0;

          color = fallbackColors[index % fallbackColors.length];
        }
      }

      return $.sanitizeColor(color);
    },

    _d3_colorizeRow: function(colDef) {
      var vizObj = this;
      return function(d) {
        return vizObj._d3_getColor(colDef, d);
      };
    },

    _d3_px: function(f) {
      if (_.isNumber(f)) {
        return f + 'px';
      } else if (_.isFunction(f)) {
        return function() {
          return f.apply(this, arguments) + 'px';
        };
      }
    },

    // Arguments are expected to be a series of 2-length arrays.
    _d3_line_path: function() {
      var r = '';
      _.each(arguments, function(p, i) {
        r += (i == 0 ? 'M ' : ' L ') + p[0] + ' ' + p[1];
      });

      return r;
    },

    // Handles the mouse entering a datum visual (bar, point, etc).
    // MUST always be called when the mouse enters, as this method
    // does some internal bookkeeping that must be kept consistent at
    // all times.
    handleDataMouseOver: function(visual, colDef, row, flyoutConfigs, enableProcessing) {
      var vizObj = this,
        view = vizObj._primaryView;

      if (row && enableProcessing) {
        var col = colDef.column;

        var configs = {
          content: vizObj.renderFlyout(row, col.tableColumnId, view),
          trigger: 'now'
        };

        $.extend(configs, flyoutConfigs);

        visual.tip = $(visual.node).socrataTip(configs);

        // We have to listen on mouse events for both the tip
        // and the slice, as the tip isn't actually a child
        // of the slice (if we didn't, w accidentally close
        // the tip if the tip shows up under the mouse).
        var mouseCount = 0;

        var mouseCleanup = function(forceCleanup) {
          if (forceCleanup === true || mouseCount == 0) {
            if (visual.tip) {
              visual.tip.destroy();
              delete visual.tip;
              delete visual.localMouseOut;
            }
          }
        };

        var localMouseIn = function() {
          mouseCount++;
        };

        var localMouseOut = function(delay) {
          mouseCount--;

          if (delay) {
            _.delay(mouseCleanup, delay);
          } else {
            mouseCleanup();
          }
        };

        visual.localMouseOut = localMouseOut;
        visual.mouseCleanup = mouseCleanup;

        var $btWrapper = $(visual.tip._tipBox);

        localMouseIn();
        $btWrapper
          .on('mouseenter', localMouseIn)
          .on('mouseout', localMouseOut);

        // Clicking on the flyout is the same as clicking on the slice/bar/whatever.
        $btWrapper.on('click', function() {
          vizObj.handleDataClick(visual, row, colDef);
        });
      }
    },

    // Handles the mouse leaving a datum visual (bar, point, etc).
    // MUST always be called when the mouse leaves, as this method
    // does some internal bookkeeping that must be kept consistent at
    // all times.
    handleDataMouseOut: function(visual, delay) {
      if (visual.localMouseOut) {
        visual.localMouseOut(delay);
      }
    },

    // Called when a data visual leaves the DOM.
    handleDataLeaveDOM: function(visual) {
      if (visual.mouseCleanup) {
        visual.mouseCleanup(true /* forceCleanup*/ );
      }
    },

    handleDataClick: function() {
      var vizObj = this;

      if (!vizObj._chartInitialized) {
        return;
      }
    }

  }, null, 'socrataChart');

})(jQuery);
